@import '../style/mixins';

.lc-podcast-widget-player {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 200px;
  background: #3F424A;
  color: #FFF;
  border-radius: 15px;
  padding: 15px;

  z-index: 2;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);

  .lc-podcast-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-right: 15px;

    .lc-podcast-image {
      border-radius: 13px;
      width: 150px; 
      height: 150px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .lc-podcast-subscribe-container {
      margin-top: 10px;
      width: 80%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      > *:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .lc-podcast-player {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    .lc-podcast-player-buttons {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 95%;
      margin-right: 10px;

      &.main {
        margin-right: 2px;
      }

      .lc-podcast-seek {
        cursor: pointer;
        font-size: 32px;
      }

      .lc-podcast-play-pause {
        cursor: pointer;
        font-size: 64px;
      }
    }

    .lc-podcast-audio {
      flex-grow: 1;
      height: 100%;
      width: 100px;
      display: flex;
      flex-direction: column;

      .lc-podcast-audio-top {
        height: 70px;
      }
      
      .lc-podcast-audio-progress {
        height: 40px;
        width: 100%;
      }

      .lc-podcast-audio-bottom {
        height: 60px;
        max-height: 60px;
        padding-bottom: 10px;

        .lc-podcast-episode-title {
          text-align: center;
          font-size: 15px;
          text-transform: uppercase;
          font-weight: bold;
          max-height: 2em;
          @include text-overflow();
        }
        height: 60px;

        .lc-podcast-title {
          margin-top: 5px;
          text-align: center;
          font-size: 12px;
          font-weight: 400;
          @include text-overflow();
        }
      }
    }
    
    .lc-powered-lilicast {
      position: absolute;
      top: 15px;
      right: 5%;
      font-size: 12px;
      color: inherit;
      text-decoration: none;
    }

    }
  .lc-podcast-actions {
    position: absolute;
    bottom: 5px;
    right: 10px;
    font-size: 20px;

    > *:not(:last-child) {
      margin-right: 10px;
    }

    .lc-podcast-episode-list,
    .lc-podcast-episode-description {
      cursor: pointer;
      opacity: 0.3;
      transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
      transform: scale(1);

      &.active {
        opacity: 1;
        transform: scale(1.1);
      }
    }

    .lc-podcast-episode-download {
      cursor: pointer;
    }
  }

  @media (max-width: $max-xs) {
    padding: 10px 15px;

    .lc-podcast-image-container { 
      width: 33%;

      .lc-podcast-image {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }

    .lc-podcast-player {
      flex-grow: 1;
      width: 1px;
      flex-direction: column;
      justify-content: space-around;

      .lc-podcast-player-buttons {
        width: 100%;
        flex-direction: row;
        justify-content: center;
        padding-top: 30px;
        max-height: 50px;
        margin-right: 0;
          
        .lc-podcast-play-pause {
          font-size: 48px;
          margin-left: 15px;
          margin-right: 5px;
        }

        .lc-playback-rate {
          font-size: 10px;
          height: 24px;
          width: 24px;
          margin-right: 15px;
        }
      }

      .lc-podcast-audio {
        width: 100%;

        .lc-podcast-audio-top {
          height: 0;
        }

        .lc-podcast-audio-progress {
          height: 40px;
        }
      }
    }
  }

  @media (max-width: $max-xxs) {
    .lc-podcast-image-container {  
      display: none;
    }
  }
}