@import './variables';

@mixin text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin hide-xs {
  @media (max-width: $max-xs) {
    display: none !important;
  }
}

@mixin hide-sm {
  @media (max-width: $max-sm) {
    display: none !important;
  }
}

@mixin hide-sm-up {
  @media (min-width: $min-sm) {
    display: none !important;
  }
}