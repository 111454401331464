.lc-progress-bar {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  
  .lc-full-bar {
    position: relative;
    height: 10px;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;

    .lc-current-bar {
      position: absolute;
      height: 10px;
      width: 100%;
      border-radius: 5px 0 0 5px;
    }
  }

}