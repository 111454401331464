@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap');
@import './style/mixins';

.lc-podcast-widget {
  font-family: 'Quicksand', sans-serif;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  transition: height 200ms ease-in-out;
  transition-delay: 200ms;

  > * {
    box-sizing: border-box; /* Safari/Chrome, other WebKit */
  }
}

.hide-xs {
  @include hide-xs();
}

.hide-sm {
  @include hide-sm();
}

.hide-sm-up {
  @include hide-sm-up();
}