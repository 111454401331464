.lc-podcast-widget-episode-description {
  transition: transform 200ms ease-in-out;
  position: absolute;
  width: 95%;
  bottom: 0;
  z-index: 1;
  overflow-y: auto;

  padding: 10px 20px;
  line-height: 2em;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);

  font-size: 13px;

  &.hidden {
    transform: translateY(-100%);
  }
  
  &.show {
    transform: translateY(0);
  }

  .lc-podcast-episode-description {
    white-space: pre-wrap;

    .lc-timestamp {
      font-weight: 600;
      cursor: pointer;
    }

    .lc-link {
      font-weight: 600;
      text-decoration: none;
      color: inherit;
    }
  }
}