@import "../style/mixins";

$episodeHeight: 50px;
$episodeNumber: 5;

.lc-podcast-widget-episodes {
  width: 95%;
  height: $episodeNumber * $episodeHeight;
  max-height: $episodeNumber * $episodeHeight;
  overflow-x: hidden;
  overflow-y: auto;

  font-size: 13px;

  
  .lc-podcast-widget-episodes-container {
    display: table;
    width: 100%;
    max-width: 100%;

    .lc-podcast-widget-episode {
      height: 50px;
      max-height: 50px;
      display: table-row;

      .lc-podcast-episode-image,
      .lc-podcast-episode-title,
      .lc-podcast-episode-duration,
      .lc-podcast-episode-download,
      .lc-podcast-episode-date {
        display: table-cell;
        vertical-align: middle;
        height: 50px;
        max-height: 50px;
        padding-left: 10px;
        padding-right: 10px;

        &:first-child {
          padding-left: 15px;
        }
        
        &:last-child {
          padding-right: 15px;
        }
      }
      
      .lc-podcast-episode-image {
        width: 40px;
        cursor: pointer;

        img {
          width: 40px;
          height: 40px;
          border-radius: 5px;
          
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        }
      }

      .lc-podcast-episode-title {
        cursor: pointer;
        max-width: 100px;
        @include text-overflow();

        .lc-podcast-episode-title-content {
          display: flex;
          flex-direction: row;
          align-items: center;
          .lc-podcast-episode-explicit {
            min-width: 12px;
            margin-right: 5px;
          }
        }

      }

      .lc-podcast-episode-duration {
        text-align: right;
        width: 40px;
      }
      
      .lc-podcast-episode-date {
        text-align: right;
        width: 80px;

        @include hide-xs();
      }
      
      .lc-podcast-episode-download {
        font-size: 20px;
        width: 40px;
        cursor: pointer;
      }
    }
  }
}