.lc-playback-rate {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  font-size: 11px;
  cursor: pointer;


  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
    border-width: 2px;
  }
}