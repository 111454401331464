@import '../style/variables';

.lc-podcast-audio-progress {
  display: flex;
  flex-direction: row;
  align-items: center;

  .lc-progress-bar {
    width: 100%;
    height: 10px;
    flex-grow: 1;
    text-align: center;
  }

  .lc-podcast-current,
  .lc-podcast-remaining {
    min-width: 35px;
  }

   
  @media (max-width: $max-sm) {
    .lc-podcast-current,
    .lc-podcast-remaining {
      min-width: 25px;
      font-size: 11px;
    }
  }

}